<template>
  <div class="form-stepper form-stepper-contract">
    <Loading :value="loading"/>
    <v-form 
      ref="form"
      v-model="valid"
      class="form pa-6"
    >
      <div class="d-flex pb-4 align-center">
        <h3>{{ $t('JOB.STEP_INTRO_TITLE') }}</h3>
        <v-btn 
          x-small
          depressed
          rounded
          color="custom"
          class="ml-4"
          @click="$emit('step', 0)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <div>
            <span class="muted">{{ $t('INPUT.TITLE') }}:</span>
            <br >
            <strong>{{ values.title }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('INPUT.URL') }}:</span>
            <br >
            <strong>{{ values.url }}</strong>
          </div>
          <div class="expandable mt-2" @click="expand">
            <span class="expandable-info" v-if="values.description">{{ $t('COMMON.EXPAND') }}</span>
            <span class="muted">{{ $t('INPUT.DESCRIPTION') }}:</span>
            <br >
            <strong>{{ values.description }}</strong>
          </div>
          <div class="expandable mt-2" @click="expand">
            <span class="expandable-info" v-if="values.profile">{{ $t('COMMON.EXPAND') }}</span>
            <span class="muted">{{ $t('INPUT.PROFILE') }}:</span>
            <br >
            <strong>{{ values.profile }}</strong>
          </div>
        </v-col>
      </v-row>

      <div class="pt-4 pb-4">
        <v-divider/>
      </div>

      <div class="d-flex pb-4 align-center">
        <h3>{{ $t('JOB.STEP_CONTRACT_TITLE') }}</h3>
        <v-btn 
          x-small
          depressed
          rounded
          color="custom"
          class="ml-4"
          @click="$emit('step', 1)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="mt-2">
            <span class="muted">{{ $t('JOB.FIELD_SECTOR') }}:</span>
            <br >
            <strong>{{ values.sectorContract }}</strong>
          </div>
          <div>
            <span class="muted">{{ $t('INPUT.CONTRACT_TYPE') }}:</span>
            <br >
            <strong>{{ values.contract_type }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('INPUT.CONTRACT_SCHEDULE') }}:</span>
            <br >
            <strong>{{ values.contract_schedule }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('JOB.SALARY') }}:</span>
            <br >
            <strong>
              {{ values.salary_min }} 
              - {{ values.salary_max }} {{ values.salary_currency }} 
              / {{ values.salary_period }} 
            </strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('INPUT.DURATION') }}:</span>
            <br >
            <strong>{{ values.duration_min }} - {{ values.duration_max }} / {{ values.duration_min_period }} </strong>
          </div>
        </v-col>
      </v-row>

      <div class="pt-4 pb-4">
        <v-divider/>
      </div>

      <div class="d-flex pb-4 align-center">
        <h3>{{ $t('JOB.STEP_COMPANY_SPECIFICATION_TITLE') }}</h3>
        <v-btn 
          x-small
          depressed
          rounded
          color="custom"
          class="ml-4"
          @click="$emit('step', 2)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <div>
            <span class="muted">{{ $t('JOB.COMPANY_TITLE') }}:</span>
            <br >
            <strong>{{ values.company_name }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('JOB.FIELD_SECTOR') }}:</span>
            <br >
            <strong>{{ sectorCompany }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('INPUT.URL') }}:</span>
            <br >
            <strong>{{ values.company_url }}</strong>
          </div>
          <div class="expandable mt-2" @click="expand">
            <span class="expandable-info" v-if="values.company_description">{{ $t('COMMON.EXPAND') }}</span>
            <span class="muted">{{ $t('INPUT.DESCRIPTION') }}:</span>
            <br >
            <strong>{{ values.company_description }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('JOB.CONTACT_NAME') }}:</span>
            <br >
            <strong>{{ values.contact_name }}</strong>
          </div>
          <div class="mt-2">
            <span class="muted">{{ $t('JOB.CONTACT_EMAIL') }}:</span>
            <br >
            <strong>{{ values.contact_email }}</strong>
          </div>
        </v-col>
      </v-row>

      <div class="pt-4 pb-4">
        <v-divider/>
      </div>

    </v-form>
    <v-divider/>
    <v-toolbar 
      color="white"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>
      <v-btn 
        color="success custom"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check</v-icon>
        <strong>{{ $t('COMMON.SAVE') }}</strong>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'FormJobStepConfirm',
  components: {
    Loading
  },
  data: (_t) => ({
    loading: false,
    valid: true,
    sectorItems: _t.$i18n.t('JOB.XTRAMILE_SECTORS_ITEMS').sort((a, b) => a.text.localeCompare(b.text)),
    sectorContract: undefined,
    sectorCompany: undefined,
  }),
  props: {
    values: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    values (n) {
      this.init()
    },
  },
  mounted () {
    this.getSectorTitle()
  },
  methods: {
    expand (e) {
      e.target.className = !e.target.className.includes('expandable-active')
        ? e.target.className.replace('expandable', 'expandable expandable-active')
        : e.target.className
    },

    validate () {
      if (this.items.id) return this.updateItem()
      return this.createItem()
    },

    createItem () {
      this.loading = true
      this.$services.api_programmatic.job.create({ values: this.values })
        .then(response => {
          this.$emit('validation', true)
          this.$emit('create', response.data[1])
          this.$emit('close')
        })
        .catch(e => {
          this.$emit('validation', false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    updateItem () {
      const data = {}
      Object.keys(this.values).forEach((key) => {
        if (this.values[key]) data[key] = this.values[key]
      })
      const values = [{
        values: data,
        where: {id: this.values.id}
      }]
      this.loading = true
      try {
        const response = this.$services.api_programmatic.job.update({ values })
        this.$emit('validation', true)
        this.$emit('update', response.data)
        this.$emit('close')
      }
      catch(e) {
        this.$emit('validation', false)
      } finally {
        this.loading = false
      }
    },

    getSectorTitle () {
      this.sectorItems.forEach(el => {
        if (el.value == this.values.xtramile_sector_id) {
          this.sectorContract = el.text
        } else if (el.value == this.values.company_sector_id) {
          console.log('el', el.value, el.text)
          this.sectorCompany = el.text
        }
      })
    }
  },
}
</script>
